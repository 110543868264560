<template>
  <div>

    <div v-if="isEmpty">
      <hb-empty-state 
        class="mt-1"
        message="No Property Groups have been created."
        btn-txt="+ Add New Property Group"
        @clicked="showPropertyGroupsModal"
      >
      </hb-empty-state>
    </div>

    <div v-else-if="dataLoaded">
      <hb-data-table-header class="pt-1">
        <template v-slot:description>
            Add and manage property groups.
        </template>
        <template v-slot:actions>
            <hb-btn small color="secondary" v-if="hasPermission('add_edit_property_groups')" @click="showPropertyGroupsModal">Add New Property Group</hb-btn>
        </template>
      </hb-data-table-header>
    
      <hb-data-table
        :headers="headers"
        :items="propertyGroups"
        @click:row="viewEdit"
      >

      <!-- Created By -->
        <template v-slot:item.pg_created_by="{ item }">
          <span>{{ item.pg_created_by }}</span>
          <br />
          <span class="hb-text-light">{{ item.created_at | formatDate("MMM DD, YYYY") }}</span>
        </template>

        <!-- Properties -->
        <template v-slot:item.properties="{ item }">
            <hb-tooltip>
              <template v-slot:item>
                <span class="border-bottom-dashed">
                    {{ item.properties.length }}
                </span>
              </template>
              <template v-slot:body>
                <div v-for="property in item.properties" :key="property.id">
                  <div>{{ property.number }} - {{ property.city }}</div>
                </div>
              </template>
            </hb-tooltip>
          </template>

          <template v-slot:item.actions="{ item }" v-if="hasEditOrDelete">
            <hb-menu options align-right content-class="view-property-groups-options-menu">
              <v-list>
                <v-list-item v-if="hasPermission('add_edit_property_groups')" @click="viewEdit(item)">
                  <v-list-item-title>
                    Edit
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="hasPermission('delete_property_groups')" @click="openDeleteModal(item)">
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
          </template>
      </hb-data-table>
    </div>

    <hb-modal v-model="confirmRemovePropertyGroup" v-if="confirmRemovePropertyGroup" size="medium" title="Delete Property Group" confirmation show-help-link>
      <template v-slot:content>
        <div class="pa-5">
          <span>You are about to delete the following Property Group:</span>  
          <br /><br />
          <strong>{{selectedPropertyGroup.name}}</strong>
          <br /><br />
          <span>Are you sure you want to delete this property group? This action cannot be undone.</span>
        </div>
      </template>
      <template v-slot:right-actions>
          <hb-btn color="destructive" @click="deletePropertyGroupItem(selectedPropertyGroup)">Delete</hb-btn>
      </template>
    </hb-modal>

    <add-edit-property-groups
      v-model="addEditPropertyGroupsModal"
      :properties="properties"
      :propertyGroup="selectedPropertyGroup"
      v-if="addEditPropertyGroupsModal"
      @close="closePropertyGroupsModal"
    >
    </add-edit-property-groups>

  </div>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import AddEditPropertyGroups from "./AddEditPropertyGroups.vue"
import { notificationMixin } from  '@/mixins/notificationMixin.js';
export default {
  name: "PropertyGroups",
  mixins: [notificationMixin],
  components: {
    AddEditPropertyGroups
  },
  data(){
    return {
      headers: [
                    { text: 'Group Name', value: 'name', width: 140},
                    { text: 'Created By', value: 'pg_created_by',  width: 140},
                    { text: '# of Properties', value: 'properties', width: 100},
                    { text: "", value: "actions", align: "right", sortable: false, width: 10}
                ],
      addEditPropertyGroupsModal: false,
      selectedPropertyGroup: {
        id: '',
        name: '',
        description: '',
        properties: []
      },
      confirmRemovePropertyGroup: false,
      dataLoaded: false
    }
  },
  async created() {
    await this.fetchData();
  },
  computed: {
     ...mapGetters({
            propertyGroups: "propertiesStore/filteredPropertyGroups",
            properties: "propertiesStore/filtered",
            hasPermission: "authenticationStore/rolePermission"
        }),
      hasEditOrDelete() {
        return this.hasPermission('add_edit_property_groups') || this.hasPermission('delete_property_groups')
      },
      isEmpty() {
        return !this.propertyGroups.length && this.dataLoaded;
      }
  },
  methods: {
    ...mapActions({
            fetchPropertyGroups: "propertiesStore/fetchPropertyGroups",
            deletePropertyGroup: "propertiesStore/deletePropertyGroup"
        }),
    async fetchData() {
      if(!this.propertyGroups.length) {
        try {
          await this.fetchPropertyGroups({hardRefresh: true});
        }
        catch(err) {
          this.showMessageNotification({description: err});
        }
      }
      this.dataLoaded = true;
    },
    showPropertyGroupsModal() {
      if(this.hasPermission('add_edit_property_groups')) this.addEditPropertyGroupsModal = true;
      else this.showMessageNotification({description: `You do not have permission to add or edit the property groups`});
    },
    closePropertyGroupsModal() {
      this.addEditPropertyGroupsModal = false;
      this.resetSelectedData();
    },
    openDeleteModal(item) {
      this.selectedPropertyGroup = item;
      this.confirmRemovePropertyGroup = true;
    },
    async deletePropertyGroupItem(item) {
      try {
        await this.deletePropertyGroup({id: item.id});
        this.confirmRemovePropertyGroup = false;
        this.resetSelectedData();
        this.showMessageNotification({type: `success`, description: `A Property Group has been deleted - ${item.name}`});
      }
      catch(err) {
        this.showMessageNotification({description: err});
      }
    },
    viewEdit(item){
      if(!this.hasPermission('add_edit_property_groups')) return;

      this.selectedPropertyGroup.id = item.id;
      this.selectedPropertyGroup.name = item.name;
      this.selectedPropertyGroup.description = item.description;
      this.selectedPropertyGroup.properties = [...item.properties];
      this.addEditPropertyGroupsModal = true;
    },
    resetSelectedData() {
      this.selectedPropertyGroup = {
        id: '',
        name: '',
        description: '',
        properties: []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.border-bottom-dashed {
    border-bottom: 1px dashed;
}
.view-property-groups-options-menu .v-list-item:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}
</style>