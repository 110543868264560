<template>
    <hb-modal v-model="dialog" show-help-link size="large" :title="selected.id ? 'Edit Property' : 'Add Property'" :no-sub-header="selected.id ? true : false" @close="closeWindow">
        <template v-slot:subheader v-if="!selected.id">
            Complete all the following fields in order to create a new property.
        </template>
        <template v-slot:content>
            <v-row
                v-show="notification.active"
                class="py-1 pl-3 my-4 mx-6"
                :class="'hb-notification-' + notification.type"
            >
                <v-col cols="10" class="ma-0 mt-2 pa-0">
                    <v-icon
                        v-if="notification.type == 'success'"
                        color="#00848E"
                        size="24"
                        class="mt-n1 ml-0 mr-2"
                    >mdi-check</v-icon>
                    <v-icon
                        v-if="notification.type == 'warning'"
                        color="#FB4C4C"
                        size="24"
                        class="mt-n1 ml-0 mr-2"
                    >mdi-close-circle</v-icon>
                    {{notification.message}}
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 text-right">
                    <hb-btn class="mr-1" icon tooltip="Close" active-state-off :hover-background-color="notification.type == 'warning' ? '#F3CCCC' : '#72d479'" @click="notification.active = false">mdi-close</hb-btn> 
                </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0">
                <v-col class="hb-forms-label" cols="4">Store ID*</v-col>
                <v-col cols="8" class="ma-0 pa-0">
                    <v-row class="mx-0 pb-0 mb-3 px-5 hb-forms-content-row">
                        <v-col cols="12" class="pa-0 ma-0">
                            <v-text-field
                                v-validate="'max:45|required'"
                                v-model="selected.number"
                                label="Store ID"
                                id="storeID"
                                data-vv-name="storeID"
                                data-vv-as="Store ID"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.storeID').length"
                                :error-messages="errors.collect('property.storeID')"
                                single-line
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0">
                <v-col class="hb-forms-label" cols="4">Property Name*</v-col>
                <v-col cols="8" class="ma-0 pa-0">
                    <v-row class="mx-0 pb-0 mb-3 px-5 hb-forms-content-row">
                        <v-col cols="12" class="pa-0 ma-0">
                            <v-text-field
                                v-validate="'max:45|required'"
                                v-model="selected.name"
                                label="Property Name"
                                id="name"
                                data-vv-name="name"
                                data-vv-as="Name"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.name').length"
                                :error-messages="errors.collect('property.name')"
                                single-line
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0">
                <v-col class="hb-forms-label" cols="4">Legal Name</v-col>
                <v-col cols="8" class="ma-0 pa-0">
                    <v-row class="mx-0 pb-0 mb-3 px-5 hb-forms-content-row">
                        <v-col cols="12" class="pa-0 ma-0">
                            <v-text-field
                                v-validate="'max:255'"
                                v-model="selected.legal_name"
                                label="Legal Name"
                                id="legal_name"
                                data-vv-name="legal_name"
                                data-vv-as="Legal Name"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.legal_name').length"
                                :error-messages="errors.collect('property.legal_name')"
                                single-line
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0" v-for="(email, i) in selected.Emails" :key="'email' + i">
                <v-col class="hb-forms-label" cols="4">Email*</v-col>
                <v-col cols="8" class="ma-0 pa-0">
                    <v-row class="mx-0 pb-0 mb-3 px-5 hb-forms-content-row">
                        <v-col cols="12" class="pa-0 ma-0">
                            <v-text-field
                                v-validate="{ required: true, max:45, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
                                v-model="selected.Emails[i].email"
                                label="Email"
                                id="email"
                                data-vv-name="email"
                                data-vv-as="Email"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.email').length"
                                :error-messages="errors.collect('property.email')"
                                single-line
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0" v-for="(phone, i) in selected.Phones" :key="'phone' + i">
                <v-col class="hb-forms-label" cols="4">Phone*</v-col>
                <v-col class="ma-0 pa-0">
                    <v-row class="mx-0 pb-0 mb-3 px-5 hb-forms-content-row">
                        <v-col cols="3" class="pa-0 ma-0 pr-3">
                            <v-autocomplete
                                :items="countryCodesList"
                                :hide-details="!errors.collect('property.phone_code_' + i).length"
                                v-model="phoneCode"
                                label="Code"
                                single-line
                                :id="'phone_code_' + i"
                                data-vv-scope="property"
                                data-vv-as="phone code"
                                :name="'phone_code'"
                                :error-messages="errors.collect('property.phone_code_' + i)"
                                @change="checkValidPhone(phoneCode, phoneNumber)"
                                @focusout="checkValidPhone(phoneCode, phoneNumber)"
                            >
                                <template v-slot:selection="data">
                                    +{{data.item}}
                                </template>
                                <template v-slot:item="data">
                                    +{{data.item}}
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="9" class="pa-0 ma-0">
                            <v-text-field
                                v-validate="'required|max:45|numeric'"
                                v-model="phoneNumber"
                                label="Phone"
                                id="phone"
                                data-vv-name="phone"
                                data-vv-as="Phone"
                                data-vv-scope="property"
                                single-line
                                :hide-details="!errors.collect('property.phone').length && checkValidPhone(phoneCode, phoneNumber)"
                                :error-messages="errors.collect('property.phone').length ? errors.collect('property.phone') : !checkValidPhone(phoneCode, phoneNumber) ? 'Please Enter a Valid Phone Number' : null"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0">
                <v-col class="hb-forms-label" cols="4">Address*</v-col>
                <v-col class="ma-0 pa-0">
                    <v-row class="mx-0 pb-0 px-5 hb-forms-content-row">
                        <v-col cols="12" class="pa-0 ma-0 mt-5">
                            <HbAutocomplete
                                :items="countriesListMixin"
                                :hide-details="!errors.collect('property.country').length"
                                v-validate="'required'"
                                v-model="selected.Address.country"
                                label="Country"
                                single-line
                                id="country"
                                data-vv-scope="property"
                                data-vv-as="Country"
                                name="country"
                                :error-messages="errors.collect('property.country')"
                                @change="setPropertyCountry"
                            >
                            </HbAutocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 pb-0 px-5 hb-forms-content-row">
                        <v-col cols="12" class="pa-0 ma-0">
                            <v-text-field
                                v-validate="'max:45|required'"
                                v-model="selected.Address.address"
                                label="Street"
                                id="address"
                                data-vv-name="address"
                                data-vv-as="Address"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.address').length"
                                :error-messages="errors.collect('property.address')"
                                single-line
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 pb-0 px-5 hb-forms-content-row">
                        <v-col cols="12" class="pa-0 ma-0">
                            <v-text-field
                                v-model="selected.Address.address2"
                                v-validate="'max:45'"
                                label="Suite / Apt"
                                id="address2"
                                data-vv-name="address2"
                                data-vv-as="Suite / Apt"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.address2').length"
                                :error-messages="errors.collect('property.address2')"
                                single-line
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 pb-0 mb-3 px-5 hb-forms-content-row">
                        <v-col cols="3" class="ma-0 pa-0 pr-3">
                            <v-text-field
                                v-validate="'required|max:45'"
                                v-model="selected.Address.zip"
                                label="Zip"
                                id="zip"
                                data-vv-name="zip"
                                data-vv-as="Zip"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.zip').length"
                                :error-messages="errors.collect('property.zip')"
                                single-line
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="ma-0 pa-0 hb-z-index-99999 margin-top-21">
                            <HbAutocomplete
                                :items="propertyStateListMixin || []"
                                item-text='name'
                                item-value='value'
                                id="state"
                                placeholder="State"
                                single_line
                                v-model.lazy="selected.Address.state"
                                data-vv-name="state"
                                data-vv-as="State"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.state').length"
                                :error_message="errors.collect('property.state')"
                                @change="setPropertyState"
                            />
                            <!-- <vuetify-statepicker
                                id="state"
                                :single_line="true"
                                v-model.lazy="selected.Address.state"
                                v-validate="'required'"
                                data-vv-name="state"
                                data-vv-as="State"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.state').length"
                                :error_message="errors.collect('property.state')"
                                :clearable="true"
                                @click:clear="selected.Address.state = ''"
                            ></vuetify-statepicker> -->
                            <!--
                            <VuetifyStatepicker
                                label="State"
                                id="state"
                                name="state"
                                v-model.lazy="selected.Address.state"
                                v-validate="'required'"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.state').length"
                                :error_message="errors.collect('property.state')"
                                :error-messages="errors.collect('property.state')"
                            ></VuetifyStatepicker>
                            -->
                        </v-col>
                        <v-col cols="5" class="ma-0 pa-0 pl-3 margin-top-21">
                            <HbAutocomplete
                                :items="propertyCityListMixin || []"
                                v-validate="propertyCityListMixin && propertyCityListMixin.length ? 'required' : ''"
                                v-model="selected.Address.city"
                                label="City"
                                id="city"
                                data-vv-name="city"
                                data-vv-as="City"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.city').length"
                                :error-messages="errors.collect('property.city')"
                                single-line
                                @keydown.enter="setPropertyCityList($event.target.value, true)"
                            >
                            </HbAutocomplete>
                            <!-- <v-text-field
                                v-validate="'required|max:45'"
                                v-model="selected.Address.city"
                                label="City"
                                id="city"
                                data-vv-name="city"
                                data-vv-as="City"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.city').length"
                                :error-messages="errors.collect('property.city')"
                                single-line
                            ></v-text-field> -->
                        </v-col>
                        <v-row>
                        <v-col cols="6" class="pt-0 hb-forms-content-row">
                            <v-text-field
                                v-validate="'max:45'"
                                v-model="selected.Address.region"
                                label="Region"
                                id="region"
                                data-vv-name="region"
                                data-vv-as="Region"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.region').length"
                                :error-messages="errors.collect('property.region')"
                                single-line
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 hb-forms-content-row">
                            <v-text-field
                                v-validate="'max:45'"
                                v-model="selected.Address.district"
                                label="District"
                                id="district"
                                data-vv-name="district"
                                data-vv-as="District"
                                data-vv-scope="property"
                                :hide-details="!errors.collect('property.district').length"
                                :error-messages="errors.collect('property.district')"
                                single-line
                            ></v-text-field>
                        </v-col>
                        </v-row>
                    </v-row>
                </v-col>
            </v-row>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" @click="save">
                <template v-if="selected.id">Save</template>
                <template v-else>Create</template>
            </hb-btn>
        </template>
    </hb-modal>
</template>

    <!--
        <v-dialog v-model="showDialog" persistent max-width="720" scrollable>
        <v-card>
            <v-card-title>
                <v-row class="ma-0">
                    <v-col cols="10 pa-0">
                        <span v-if="selected.id">Edit Property</span>
                        <span v-else>Add Property</span>
                    </v-col>
                    <v-col cols="2" class="pa-0 text-right">
                        <v-icon @click="closeWindow">mdi-close</v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-row
                v-show="notification.active"
                class="py-1 pl-3 my-4 mx-6"
                :class="'hb-notification-' + notification.type"
            >
                <v-col cols="10" class="ma-0 mt-2 pa-0">
                    <v-icon
                        v-if="notification.type == 'success'"
                        color="#00848E"
                        size="24"
                        class="mt-n1 ml-0 mr-2"
                    >mdi-check</v-icon>
                    <v-icon
                        v-if="notification.type == 'warning'"
                        color="#FB4C4C"
                        size="24"
                        class="mt-n1 ml-0 mr-2"
                    >mdi-close-circle</v-icon>
                    {{notification.message}}
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 text-right">
                    <v-btn icon class="mr-1" :ripple="false" @click="notification.active = false">
                        <v-icon color="#101318">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card-text class="pa-0">
                <v-row class="hb-table-row ma-0 pa-0 pl-3" v-if="!selected.id">
                    <v-col>Enter the Store ID, name and the address to create a property.</v-col>
                </v-row>
                <v-row class="hb-table-row ma-0 pa-0">
                    <v-col class="hb-table-label py-4 pl-6" cols="4">Store ID</v-col>
                    <v-col cols="8" class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-validate="'required'"
                                    v-model="selected.number"
                                    label="Enter ID"
                                    id="storeID"
                                    data-vv-name="storeID"
                                    data-vv-as="Store ID"
                                    data-vv-scope="property"
                                    :hide-details="!errors.collect('property.storeID').length"
                                    :error-messages="errors.collect('property.storeID')"
                                    single-line
                                    dense
                                    flat
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row ma-0 pa-0">
                    <v-col class="hb-table-label py-4 pl-6" cols="4">Name</v-col>
                    <v-col cols="8" class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-validate="'required'"
                                    v-model="selected.name"
                                    label="Enter Name"
                                    id="name"
                                    data-vv-name="name"
                                    data-vv-as="Name"
                                    data-vv-scope="property"
                                    :hide-details="!errors.collect('property.name').length"
                                    :error-messages="errors.collect('property.name')"
                                    single-line
                                    dense
                                    flat
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row ma-0 pa-0">
                    <v-col class="hb-table-label py-4 pl-6" cols="4">Address</v-col>
                    <v-col cols="8" class="py-0">
                        <v-row>
                            <v-col cols="12" sm="7">
                                <v-text-field
                                    v-validate="'required'"
                                    v-model="selected.Address.address"
                                    label="Enter Address"
                                    id="address"
                                    data-vv-name="address"
                                    data-vv-as="Address"
                                    data-vv-scope="property"
                                    :hide-details="!errors.collect('property.address').length"
                                    :error-messages="errors.collect('property.address')"
                                    single-line
                                    dense
                                    flat
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-text-field
                                    v-validate="'required'"
                                    v-model="selected.Address.city"
                                    label="Enter City"
                                    id="city"
                                    data-vv-name="city"
                                    data-vv-as="City"
                                    data-vv-scope="property"
                                    :hide-details="!errors.collect('property.city').length"
                                    :error-messages="errors.collect('property.city')"
                                    single-line
                                    dense
                                    flat
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="7">
                                <VuetifyStatepicker
                                    label="State"
                                    id="state"
                                    name="state"
                                    v-model.lazy="selected.Address.state"
                                    v-validate="'required'"
                                    data-vv-scope="property"
                                    :hide-details="!errors.collect('property.state').length"
                                    :error_message="errors.collect('property.state')"
                                    :error-messages="errors.collect('property.state')"
                                ></VuetifyStatepicker>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-text-field
                                    v-validate="'required'"
                                    v-model="selected.Address.zip"
                                    label="Enter Zip"
                                    id="zip"
                                    data-vv-name="zip"
                                    data-vv-as="Zip"
                                    data-vv-scope="property"
                                    :hide-details="!errors.collect('property.zip').length"
                                    :error-messages="errors.collect('property.zip')"
                                    single-line
                                    dense
                                    flat
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-3 text-right">
                <v-col class="pt-1 pb-1">
                    <a class="close-link" @click="closeWindow">Cancel</a>
                    <v-btn color="primary" large @click="save">
                        <template v-if="selected.id">Edit</template>
                        <template v-else>Create</template>
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
    -->

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import VuetifyStatepicker from "../../includes/VuetifyStatepicker";
import { parsePhoneNumber } from 'libphonenumber-js';
import { notificationMixin } from "../../../mixins/notificationMixin.js";
import { countryStateCityMixin } from '../../../mixins/countryStateCityMixin';

export default {
    name: "AddEditProperty",
    data: () => ({
        selected: {
            name: "",
            legal_name: '',
            number: "",
            Emails: [
                {
                    id: null,
                    type: "Main",
                    email: ""
                }
            ],
            Phones: [
                {
                    id: null,
                    type: "Main",
                    phone: ""
                }
            ],
            Address: {
                address: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                region: "",
                district: ""
            }
        },
        notification: {
            active: false,
            type: "",
            message: ""
        },
        countryCodesList: [
            "1",
            "7",
            "20",
            "27",
            "30",
            "31",
            "32",
            "33",
            "34",
            "36",
            "39",
            "40",
            "41",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "51",
            "52",
            "53",
            "54",
            "55",
            "56",
            "57",
            "58",
            "60",
            "61",
            "62",
            "63",
            "64",
            "65",
            "66",
            "81",
            "82",
            "84",
            "86",
            "90",
            "91",
            "92",
            "93",
            "94",
            "95",
            "98",
            "211",
            "212",
            "213",
            "216",
            "218",
            "220",
            "221",
            "222",
            "223",
            "224",
            "225",
            "226",
            "227",
            "228",
            "229",
            "230",
            "231",
            "232",
            "233",
            "234",
            "235",
            "236",
            "237",
            "238",
            "239",
            "240",
            "241",
            "242",
            "243",
            "244",
            "245",
            "246",
            "247",
            "248",
            "249",
            "250",
            "251",
            "252",
            "253",
            "254",
            "255",
            "256",
            "257",
            "258",
            "260",
            "261",
            "262",
            "263",
            "264",
            "265",
            "266",
            "267",
            "268",
            "269",
            "290",
            "291",
            "297",
            "298",
            "299",
            "350",
            "351",
            "352",
            "353",
            "354",
            "355",
            "356",
            "357",
            "358",
            "359",
            "370",
            "371",
            "372",
            "373",
            "374",
            "375",
            "376",
            "377",
            "378",
            "380",
            "381",
            "382",
            "383",
            "385",
            "386",
            "387",
            "389",
            "420",
            "421",
            "423",
            "500",
            "501",
            "502",
            "503",
            "504",
            "505",
            "506",
            "507",
            "508",
            "509",
            "590",
            "591",
            "592",
            "593",
            "594",
            "595",
            "596",
            "597",
            "598",
            "599",
            "670",
            "672",
            "673",
            "674",
            "675",
            "676",
            "677",
            "678",
            "679",
            "680",
            "681",
            "682",
            "683",
            "685",
            "686",
            "687",
            "688",
            "689",
            "690",
            "691",
            "692",
            "850",
            "852",
            "853",
            "855",
            "856",
            "880",
            "886",
            "960",
            "961",
            "962",
            "963",
            "964",
            "965",
            "966",
            "967",
            "968",
            "970",
            "971",
            "972",
            "973",
            "974",
            "975",
            "976",
            "977",
            "992",
            "993",
            "994",
            "995",
            "996",
            "998"
        ],
        phoneCode: '1',
        phoneNumber: ''
    }),
    mixins: [notificationMixin, countryStateCityMixin],
    props: ["activePropertyID", "setNotification", "value"],
    components: {
        VuetifyStatepicker
    },
    created(){
        this.setCountriesListEvent()
    },
    destroyed(){
        this.resetMissingCityList()
    },
    computed: {
        ...mapGetters({
            property: "propertiesStore/property"
        }),
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    mounted() {
        if (this.$props.activePropertyID && this.property.id) {
            this.selected = JSON.parse(JSON.stringify(this.property));

            this.selected.Address.country = this.setCountryNameEvent(this.property.Address.country)
            this.selected.Address.state = this.property.Address.state || ''

            this.setPropertyStatesListEvent(this.selected.Address.country)
            this.setPropertyCityList(this.property.Address.city)

            if(!this.selected.Address.state){
                this.setPropertyCountryCitiesListEvent(this.selected.Address.country)
            }else{
                this.setPropertyCitiesListEvent(this.selected.Address.country, this.selected.Address.state)
            }

            

            if(this.selected.Emails && this.selected.Emails.length === 0){
                this.selected.Emails = [
                    {
                        id: null,
                        type: "Main",
                        email: ""
                    }
                ]
            }
            if(this.selected.Phones && this.selected.Phones.length && this.selected.Phones[0] && this.selected.Phones[0].id && this.selected.Phones[0].phone && this.selected.Phones[0].phone.length > 0){
                try {
                    var parseEditedPhone = parsePhoneNumber('+' + this.selected.Phones[0].phone);
                } catch(err) {
                    console.log(err);
                }

                if(!parseEditedPhone){
                    this.phoneCode = '';
                    this.phoneNumber = this.selected.Phones[0].phone;
                } else {
                    this.phoneCode = parseEditedPhone.countryCallingCode;
                    this.phoneNumber = parseEditedPhone.nationalNumber;
                }
            } else if(this.selected.Phones && this.selected.Phones.length === 0){
                this.selected.Phones = [
                    {
                        id: null,
                        type: "Main",
                        phone: this.phoneCode + this.phoneNumber
                    }
                ]
            }
        }else{
            this.selected.Address.country = this.defaultCountry
            this.setPropertyStatesListEvent(this.selected.Address.country)
            this.setPropertyCountryCitiesListEvent(this.selected.Address.country)
        }
    },
    methods: {
        ...mapActions({
            addProperty: "propertiesStore/addProperty",
            editProperty: "propertiesStore/editProperty",
        }),
        setPropertyCountry(){
            this.selected.Address.state = ''
            this.selected.Address.city = ''
            this.setPropertyStatesListEvent(this.selected.Address.country)
            this.setPropertyCountryCitiesListEvent(this.selected.Address.country)
        },
        setPropertyState(){
            this.selected.Address.city = ''
            this.setPropertyCitiesListEvent(this.selected.Address.country, this.selected.Address.state)
        },
        setPropertyCityList(value, toValidate = false){
            if(!value) return
            if(toValidate){
                const isSpecialCharacter = this.validateCityName(value)
                const cityValidation = this.errors.items.indexOf('property.city')
                this.errors.items.splice(cityValidation);
                if(isSpecialCharacter){
                  return this.errors.add({
                            field: 'property.city',
                            msg: 'Please add a valid city name.'
                        }) 
                }
            }
            const isCityExists = this.propertyCityListMixin.some((city) => city.toLowerCase() === value.toLowerCase())
            if(isCityExists) return

            this.propertyCityListMixin.push(value)
            const country = this.countriesListMixinRaw.find(item => item.name === this.selected.Address.country);
            this.addMissingCity({
                country_code: country.isoCode,
                state_code: this.selected.Address.state,
                city: value
            })
        },
        async save() {
            let status = await this.$validator.validateAll("property");
            if (!status) {
                return;
            }

            let data = this.selected;
            if(this.phoneCode.length && this.phoneNumber.length){
                let parsedPhoneNumber;

                parsedPhoneNumber = parsePhoneNumber('+' + this.phoneCode + this.phoneNumber);

                if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                    data.Phones = [
                        {
                            id: this.selected.Phones[0].id && this.selected.Phones[0].id.length ? this.selected.Phones[0].id : null,
                            type: "Main",
                            phone: parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber
                        }
                    ]
                }
                else {
                    this.showMessageNotification({ id: this.$options.name, type: "error", description: 'Please Enter a Valid Phone Number' });
                    return;
                }
            } else {
                data.Phones = [
                    {
                        id: this.selected.Phones[0].id && this.selected.Phones[0].id.length ? this.selected.Phones[0].id : null,
                        type: "Main",
                        phone: ""
                    }
                ]
            }

            if(this.selected.Emails[0] && this.selected.Emails[0].email && this.selected.Emails[0].email.length){
                data.Emails = [
                    {
                        id: this.selected.Emails[0].id && this.selected.Emails[0].id.length ? this.selected.Emails[0].id : null,
                        type: "Main",
                        email: this.selected.Emails[0].email
                    }
                ]
            } else {
                data.Emails = [
                    {
                        id: this.selected.Emails[0].id && this.selected.Emails[0].id.length ? this.selected.Emails[0].id : null,
                        type: "Main",
                        email: ""
                    }
                ]
            }

            if (this.selected.id) {
                await this.editProperty(data).then(
                    response => {
                        this.showMessageNotification({ id: this.$options.name, type: "success", description: 'The property "' + data.name + '" has been edited.' });
                        this.closeWindow();
                    },
                    err => {
                        this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }] });
                    }
                );
            } else {
                await this.addProperty(data).then(
                    response => {
                        this.showMessageNotification({ id: this.$options.name, type: "success", description: 'The property "' + data.name + '" has been added. Please note: if you wish to edit the details of this property, you must first log out and log back in.' });
                        this.closeWindow();
                    },
                    err => {
                        this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }] });
                    }
                );
            }
        },
        closeWindow() {
            this.selected = {
                name: "",
                number: "",
                Emails: [
                    {
                        id: null,
                        type: "Main",
                        email: ""
                    }
                ],
                Phones: [
                    {
                        id: null,
                        type: "Main",
                        phone: ""
                    }
                ],
                Address: {
                    address: "",
                    address2: "",
                    city: "",
                    state: "",
                    country: "",
                    zip: "",
                    region: "",
                    district: ""
                }
            };
            this.$nextTick(() => {
                this.$emit("close");
            });
        },
        checkValidPhone(code, number){
            if(code || number){
            try {
                var parsedPhoneNumber = parsePhoneNumber('+' + code + number);
            } catch(err) {
                console.log(err);
            }
            var r = null;
            if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                this.invalidPhone = false;
                r = true;
            } else if((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number.length) {
                this.invalidPhone = true;
                r = false;
            } else {
                this.invalidPhone = false;
                r = true;
            }
            return r;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
 .margin-top-21{
    margin-top: 21px !important
 }
</style>