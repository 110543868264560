<template>
  <div>
    <hb-modal 
      v-model="dialog"
      :title="titleText"
      @close="closeWindow"
    >
        <template v-slot:subheader>
          {{subHeaderText}}
        </template>

        <template v-slot:content >
          <div style="height:568px;">
              <hb-form label="Group Name" required>
                <HbTextField
                  v-model="formData.name"
                  v-validate="'required|max:45'"
                  data-vv-scope="default"
                  data-vv-name="group_name"
                  data-vv-as="Group Name"
                  :error="errors.has('default.group_name')"
                  placeholder="Enter Group Name"
                >
                </HbTextField> 
              </hb-form>
              <hb-form label="Group Description*">
                 <HbTextField
                  v-model="formData.description"
                  v-validate="'required'"
                  data-vv-scope="default"
                  data-vv-name="description"
                  data-vv-as="description"
                  :error="errors.has('default.description')"
                  placeholder="Enter Group Description"
                >
                </HbTextField> 
              </hb-form>
              <hb-form label="Properties" required>
                <hb-combobox
                  v-model="formData.properties"
                  :items="properties"
                  v-validate="'required'"
                  id="properties"
                  name="properties"
                  data-vv-scope="default"
                  data-vv-as="Properties"
                  data-vv-name="properties"
                  label="Select Properties"
                  :error="errors.collect('default.properties').length > 0"
                  select
                  item-value="id"
                  item-text="name"
                  return-object
                >
                  <template v-slot:item="{item, attrs}">
                    <v-list-item-action> 
                      <HbIcon
                        :mdi-code="attrs.inputValue ? 'check_box' : 'mdi-checkbox-blank-outline'"
                        :color="attrs.inputValue ? '#00848E' : '#9b9b9b'"
                      />
                    </v-list-item-action>
                    <v-list-item-content> 
                        <v-list-item-title> 
                            <span>{{ item.number }} - {{item.name}}</span>
                        </v-list-item-title> 
                    </v-list-item-content> 
                  </template>
                </hb-combobox>

              </hb-form>
          </div>
        </template>  
        <template v-slot:right-actions>
          <hb-btn color="primary" @click="submitForm">
            Save
          </hb-btn>
        </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import { mapActions, mapGetters } from "vuex";
import { notificationMixin } from  '@/mixins/notificationMixin.js';
export default {
  name:  "AddEditPropertyGroups",
  mixins: [notificationMixin],
  props: {
        value: {
          type: Boolean,
          default: false
        },
        properties: {
          type: Array,
          default: []
        },
        propertyGroup: {
          type: Object,
          default: {}
        }
    },
  created() {
    this.editMode = !!this.propertyGroup.id;
    if(this.editMode) this.formData = JSON.parse(JSON.stringify(this.propertyGroup));
  },

  data( ) {
    return {
      formData: {
        name: '',
        description: '',
        properties: []
      },
      editMode: false
    }
  },
  computed: {
    ...mapGetters({
      selectedPropertyGroup: 'propertiesStore/selectedPropertyGroup'
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    titleText() {
      return this.editMode ? `Edit Property Group` : `Add New Property Group`;
    },
    subHeaderText() {
      return this.editMode ? `Edit and manage property groups.` : `Add and manage property groups.`;
    }
  },
  methods: {
    ...mapActions({
            exchangeToken: 'propertiesStore/exchangeToken',
            fetchPropertyGroups: "propertiesStore/fetchPropertyGroups",
            addPropertyGroup: "propertiesStore/addPropertyGroup",
            updatePropertyGroup: "propertiesStore/updatePropertyGroup"
        }),
    async submitForm() {
      const valid = await this.$validator.validateAll('default');
      if(!valid) return;
  
      try {
        let data = {
          name: this.formData.name,
          description: this.formData.description,
          property_ids: this.formData.properties.map(p => p.id)
        }
        if(this.editMode) {
          let payload = {propertyGroupId: this.propertyGroup.id, data}
          
          if(data.property_ids.length != this.propertyGroup.properties.length && this.selectedPropertyGroup.id === this.propertyGroup.id) 
            await this.exchangeToken({property_ids: data.property_ids});
          
          await this.updatePropertyGroup(payload);
          this.showMessageNotification({type: `success`, description: `Edits have been made to group - ${data.name}`});
        }
        else {
          await this.addPropertyGroup(data);
          this.showMessageNotification({type: `success`, description: `A new group has been added - ${data.name}`});
        }
        this.resetFormData();
        this.$emit("close");
      }
      catch(err) {
        this.showMessageNotification({ description: err })
      }

    },
    closeWindow() {
      this.$emit("close");
      this.resetFormData();
    },
    resetFormData() {
      this.formData = {
        name: '',
        description: '',
        properties: []
      }
    }

  }
}
</script>

<style lang="scss" scoped>

.modal-height {
    height:568px
}

</style>
